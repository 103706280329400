import React, { useEffect } from "react"
import "./PersonalData.scss"
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getMeTunk } from "../../../features/login/loginAPI";
import { useTranslation } from "react-i18next";

export const PersonalData = () => {
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const { userInfo } = useSelector((state) => state.login)
  const dispatch = useDispatch()
  const formData = watch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const user = localStorage.personal_data

    if (user) {
      const data = JSON.parse(user)
      setValue("firstName", data.firstName);
      setValue("lastName", data.lastName);
      setValue("phoneNumber", data.phoneNumber);
      setValue("email", data.email);
    } else {
      if (userInfo.id) {
        setValue("firstName", userInfo.firstName || "");
        setValue("lastName", userInfo.lastName || "");
        setValue("phoneNumber", userInfo.phone || "");
        setValue("email", userInfo.email || "");
      }
    }
  }, [userInfo]);

  useEffect(() => {
    setTimeout(() => {
      if (userInfo.id) {
        localStorage.setItem('personal_data', JSON.stringify(formData))
      }
    }, 100)
  }, [formData])

  useEffect(() => {
    if (localStorage.step === 1) {
      localStorage.removeItem('delivery_address')
    }
  }, [])

  return (
    <div className="personal_data">
      <form >
        <div>
          <div>
            <input {...register("firstName", { required: true })} placeholder={t('reg.first')} defaultValue={userInfo?.firstName} />
            {formData.firstName === '' && <small className="error">{t('reg.error')}</small>}
          </div>
          <div>
            <input {...register("lastName", { required: true })} placeholder={t('reg.last')}  defaultValue={userInfo?.lastName} />
            {formData.lastName === '' && <small className="error">{t('reg.error')}</small>}
          </div>
        </div>
        <div>
          <div>
            <input {...register("phoneNumber", { required: true })} type="number" placeholder={t('reg.phone')} defaultValue={userInfo?.phone} />
            {formData.phoneNumber === '' && <small className="error">{t('reg.error')}</small>}
          </div>
        </div>
        <div>
          <div>
            <input {...register("email", { required: true })} placeholder={t('reg.email')} defaultValue={userInfo?.email} />
            {formData.email === '' && <small className="error">{t('reg.error')}</small>}
          </div>
        </div>
      </form>
    </div>
  )

}