import React from 'react';
import "./Contact.scss"
import { useTranslation } from 'react-i18next';

export const Contact = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className='contact_us'>
      <div className='contact'>
        <div className='contact_title' >
          <p>{t('contact.contact')}</p>
        </div>
        <div className='contact_main'>
          <div className='contact_main_left'>
            <div>
              <span>{t('contact.info')}</span>
              <p>{t('contact.info_2')}</p>
            </div>
            <div>
              <span>{t('contact.title_1')}</span>
              <p>{t('contact.desc_1')}</p>
              <span>{t('contact.title_2')}</span>
              <p>{t('contact.desc_2')}</p>

              <span>{t('contact.title_4')}</span>
              <p>{t('contact.desc_4')}</p>

              <span>{t('contact.title_5')}</span>
              <p>{t('contact.desc_5')}</p>

            </div>
            <div className='contact_info'>
              <p>{t('privacy.privacy')}  <a href="/privacy">IdealGift.am</a> </p>
            </div>
          </div>

          <div className='contact_main_right'>
            <div>
              <span>{t('contact.title_6')}</span>
            </div>
            <div>
              <p>{t('contact.desc_6')}</p>
            </div>
            <div>
              <span>{t('contact.title_7')}</span>
              <p>{t('contact.desc_7')}</p>
              <span>{t('contact.title_8')}</span>
              <p>{t('contact.desc_8')}</p>
              <span>{t('contact.title_9')}</span>
              <p>{t('contact.desc_9')}</p>
            </div>

            <div>
              <p>{t('contact.desc_10_1')}</p>
            </div>
            <div>
              <p>{t('contact.desc_10_2')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}