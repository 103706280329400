import React, { useEffect } from "react";
import './Payment.scss'
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { deleteAllBasketItems, getBasketCount } from "../../features/products/productsSlice";
import { useTranslation } from "react-i18next";

export const Payment = () => {
  const location = useLocation();
  const pathname = location.search;
  const navigate = useNavigate()
  const decodedDescription = decodeURIComponent(pathname)
  const params = new URLSearchParams(decodedDescription);
  const { userInfo } = useSelector((state) => state.login)
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation();
  
  const orderID = params.get("orderID");
  const paymentID = params.get("paymentID");
  const responseCode = params.get("resposneCode");
  const opaque = params.get("opaque");
  const description = params.get("description")?.replace(/\+/g, " ");

  const addOrder = async () => {
    const basket = JSON.parse(localStorage.getItem('basket'))
    if (!basket) {
      navigate('/')
    } else {
      const delMetod = localStorage.getItem('delMetod')
      const peyMet = localStorage.getItem('peyMet')
      const totalPrice = localStorage.getItem('totalPrice')
      const deliveryPrice = localStorage.getItem('deliveryPrice')
      const personal_data = JSON.parse(localStorage.getItem('personal_data'))
      const delivery_address = JSON.parse(localStorage.getItem('delivery_address'))
      const note = localStorage.getItem('note')

      const orderData = {
        price: Number(totalPrice) - Number(deliveryPrice),
        totalPrice: Number(totalPrice),
        code: 'code',
        fName: personal_data?.firstName,
        lName: personal_data?.lastName,
        phoneNumber: personal_data?.phoneNumber,
        email: personal_data?.email,
        city: delivery_address?.city,
        addres: delivery_address?.address,
        apartment: delivery_address?.apartment,
        floor: delivery_address?.floor,
        intercum: delivery_address?.intercom,
        delMetod: delMetod,
        peyMetod: peyMet,
        orderNot: note ? note : '',
        products: basket.map((item) => {
          return {
            id: item.id,
            count: item.count,
            name: item.name
          }
        }),
        UserId: userInfo.id,
      }
      await axios.post(`${process.env.REACT_APP_BASE_URL}/product/add/order`, orderData)

      localStorage.removeItem('delMetod')
      localStorage.removeItem('deliveryPrice')
      localStorage.removeItem('note')
      localStorage.removeItem('peyMet')
      localStorage.removeItem('step')
      localStorage.removeItem('totalPrice')
      localStorage.removeItem('personal_data')
      localStorage.removeItem('delivery_address')
      dispatch(deleteAllBasketItems())
    }
  }

  const redirectHome = () => {
    navigate('/')
  }

  useEffect(() => {
    addOrder()
  }, [])

  return (
    <div className="payment">
      <div className="payment_container">
        <span>{description ? description : 'Order Created'}</span>
        <img width={80} height={90} src="/svg/payment.svg" alt="" />
        <p>{paymentID}</p>
        <p>{t('payment.title')} </p>
        <button onClick={() => redirectHome()}>{t('payment.title_2')}</button>
      </div>
    </div>
  )
}