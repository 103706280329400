import React, { useEffect, useState } from 'react';
import styles from "./Message.module.scss"
import axios from 'axios';

export const Message = () => {
  const [activeOrder, setActiveOrder] = useState(null);

  const toggleOrder = (index) => {
    setActiveOrder(activeOrder === index ? null : index);
  };
  const [messages, setMessages] = useState([])
 const getMesseges = async () =>{
   const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}/product/get/corporative`)
   if(data){
    setMessages(data)
   }
 }
 const rempveItem = async(id)=>{
  setMessages(messages.filter((item)=>item.id !== id))
  await axios.post(`${process.env.REACT_APP_BASE_URL}/product/corporative`, {id})
 }
  useEffect(()=>{
    getMesseges()
  },[])
  return (
    <div className={styles.accordion}>
      <div className={styles.headerRow}>
        <span>First Name</span>
        <span>Last Name</span>
        <span>Phone Number</span>
        <span>Email</span>
      </div>
      {
        messages && messages?.map((message, index) => (
        <div key={index} className={`${styles.order} ${activeOrder === index ? styles.active : ""}`}>
          <div
            className={`${styles.row} ${activeOrder === index ? styles.active : ""}`}
            onClick={() => toggleOrder(index)}
          >
            <span>{message?.fName}</span>
            <span>{message?.lName}</span>
            <span>{message?.phone}</span>
            <span>{message?.email}</span>
            <button onClick={()=>rempveItem(message?.id)}>remove</button>
            <div className={styles.toggleIcon}>
              {activeOrder === index ? "▲" : "▼"}
            </div>
          </div>
          {activeOrder === index && message.messeg && (
            <div className={styles.details}>
              <div className = {
                styles.product
              } >
                <p>Message:</p>
              </div>
              <div>
                <p>{message.messeg}</p>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}