import { createSlice } from "@reduxjs/toolkit";
import { getMeTunk } from "../login/loginAPI";
import { activetedProductTunk, addProductTunk, deleteProductTunk, editProductTunk, getAllProductTunk, getProductTunk } from "./productsAPI";

const productsSlice = createSlice({
  name: "products",
  initialState: {
    value: 0,
    basket: [],
    basketCount: 0,
    totalPrice: 0,
    delivery: 0,
    deliveryPriceList: false,
    productList: [],
    product: {},
    addStatus:"",
    getStatus:"",
    getAllStatus:"",
    deleteStatus:"",
    editStatus:"",
    activetedStatus:"",
    errorMessage: ''
  },

  reducers: {
    addBasket: (state, action) => {
      const localData = JSON.parse(localStorage.getItem("basket"));
      if (localData) {
        state.basket = localData;
        state.basketCount = localData?.length;
      } else {
        state.basket = [];
      }
      state.basket.push(action.payload);
      localStorage.setItem("basket", JSON.stringify(state.basket));
      state.basketCount = state.basket?.length;
    },
    getBasketCount: (state, action) => {
      state.basketCount =
        JSON.parse(localStorage.getItem("basket"))?.length || 0;
    },
    addErrorMessage:(state,action)=>{
      state.errorMessage = action.payload
    },
    getBasket: (state, action) => {
      state.basket = JSON.parse(localStorage.getItem("basket"));
      const total =
        state.basket !== null
          ? state.basket.reduce((acc, item) => acc + item.count * item.price, 0)
          : 0;
      state.totalPrice = total;
      localStorage.setItem("totalPrice", total);
    },
    deleteBasketItems: (state, action) => {
      state.basket = state.basket.filter((elm) => elm.id !== action.payload);
      localStorage.setItem("basket", JSON.stringify(state.basket));
      const total =
        state.basket !== null
          ? state.basket.reduce((acc, item) => acc + item.count * item.price, 0)
          : 0;
      localStorage.setItem("totalPrice", total);
    },
    deleteAllBasketItems: (state, action) => {
      state.basket = [];
      localStorage.removeItem("basket");
      state.basketCount = 0;
      const total =
        state.basket !== null
          ? state.basket.reduce((acc, item) => acc + item.count * item.price, 0)
          : 0;
      localStorage.setItem("totalPrice", total);
    },

    productCountPlus: (state, action) => {
      const updatedBasket = state.basket.map((elm) => {
        if (elm.id === action.payload) {
          return { ...elm, count: elm.count + 1 };
        }
        return elm;
      });
      state.basket = updatedBasket;
      const total =
        state.basket !== null
          ? state.basket.reduce((acc, item) => acc + item.count * item.price, 0)
          : 0;
      localStorage.setItem("totalPrice", total);
      localStorage.setItem("basket", JSON.stringify(updatedBasket));
    },
    addDeliveryPrice: (state, action) => {
      const total =
        state.basket !== null
          ? state.basket.reduce((acc, item) => acc + item.count * item.price, 0)
          : 0;

      state.totalPrice = total + action.payload;
      localStorage.setItem("totalPrice", state.totalPrice);
      state.delivery = action.payload;
      localStorage.setItem("deliveryPrice", state.delivery);
    },
    productCountMinus: (state, action) => {
      const updatedBasket = state.basket.map((elm) => {
        if (elm.id === action.payload) {
          return { ...elm, count: elm.count > 1 ? elm.count - 1 : elm.count };
        }
        return elm;
      });
      state.basket = updatedBasket;
      const total =
        state.basket !== null
          ? state.basket.reduce((acc, item) => acc + item.count * item.price, 0)
          : 0;
      localStorage.setItem("totalPrice", total);
      localStorage.setItem("basket", JSON.stringify(updatedBasket));
    },

    deliveryPriceListOpen: (state, action) => {
      state.deliveryPriceList = true;
    },
    deliveryPriceListClose: (state, action) => {
      state.deliveryPriceList = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(addProductTunk.pending, (state) => {
        state.addStatus = "loading";
      })
      .addCase(addProductTunk.fulfilled, (state, action) => {
        state.addStatus = "succeeded";
      })
      .addCase(addProductTunk.rejected, (state, action) => {
        state.addStatus = "failed";
      });
    builder
      .addCase(getProductTunk.pending, (state) => {
        state.getStatus = "loading";
      })
      .addCase(getProductTunk.fulfilled, (state, action) => {
        state.getStatus = "succeeded";
        state.product = action.payload;
      })
      .addCase(getProductTunk.rejected, (state, action) => {
        state.getStatus = "failed";
      });
    builder
      .addCase(getAllProductTunk.pending, (state) => {
        state.getAllStatus = "loading";
      })
      .addCase(getAllProductTunk.fulfilled, (state, action) => {
        state.getAllStatus = "succeeded";
        state.productList = action.payload;
      })
      .addCase(getAllProductTunk.rejected, (state, action) => {
        state.getAllStatus = "failed";
      });
    builder
      .addCase(deleteProductTunk.pending, (state) => {
        state.deleteStatus = "loading";
      })
      .addCase(deleteProductTunk.fulfilled, (state, action) => {
        state.deleteStatus = "succeeded";
      })
      .addCase(deleteProductTunk.rejected, (state, action) => {
        state.deleteStatus = "failed";
      });
    builder
      .addCase(editProductTunk.pending, (state) => {
        state.editStatus = "loading";
      })
      .addCase(editProductTunk.fulfilled, (state, action) => {
        state.editStatus = "succeeded";
      })
      .addCase(editProductTunk.rejected, (state, action) => {
        state.editStatus = "failed";
      });
    builder
      .addCase(activetedProductTunk.pending, (state) => {
        state.activetedStatus = "loading";
      })
      .addCase(activetedProductTunk.fulfilled, (state, action) => {
        state.activetedStatus = "succeeded";
      })
      .addCase(activetedProductTunk.rejected, (state, action) => {
        state.activetedStatus = "failed";
      });
  },
});

export const {
  addBasket,
  deleteBasketItems,
  getBasketCount,
  productCountMinus,
  productCountPlus,
  getBasket,
  deleteAllBasketItems,
  addDeliveryPrice,
  deliveryPriceListOpen,
  deliveryPriceListClose,
  addErrorMessage
} = productsSlice.actions;
export default productsSlice.reducer;
