import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const addCategoryTunk = createAsyncThunk(
  'category/addCategory',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/product/add/category`, data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({ message: 'An unexpected error occurred', status: error.response?.status || 500 })
    }
  }
);
export const getCategoryTunk = createAsyncThunk(
  'category/getCategory',
  async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/product/get/category`);
      return response.data;
    } catch (error) {
      if (error) {
        return error.response.data;
      }
      return { message: 'An unexpected error occurred', status: error.response?.status || 500 }
    }
  }
);

export const editCategoryTunk = createAsyncThunk(
  'category/editCategory',
  async (data) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/product/edit/category`,data);
      return response.data;
    } catch (error) {
      if (error) {
        return error.response.data;
      }
      return { message: 'An unexpected error occurred', status: error.response?.status || 500 }
    }
  }
);