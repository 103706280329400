import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { addCategoryTunk, editCategoryTunk, getCategoryTunk } from "../../../features/category/categoryAPI";
import "./Category.scss"

export const Category = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const { register, reset, handleSubmit, formState: { errors } } = useForm();
  const form1 = useForm();
  const dispatch = useDispatch()
  const { categoryList } = useSelector((state) => state.category);

  const addCategory = (data) => {
    dispatch(addCategoryTunk(data))
    reset()
  }

  const openModal = (Category) => {
    setModalOpen(true);
    setCurrentCategory(Category);
    form1.setValue("name", Category.name);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentCategory(null);
  };

  const submitEdit = async (data) => {
    await dispatch(editCategoryTunk({ name: data.name, id: currentCategory.id }))
    dispatch(getCategoryTunk())
    closeModal();
  };

  useEffect(() => {
    dispatch(getCategoryTunk())
  }, [])
  return (
    <div className="category">
      <div className="category_control">
        <form onSubmit={handleSubmit(addCategory)}>
          <input {...register("name", { required: true })} type="text" placeholder="Category Name" />
          <button type="submit">add</button>
        </form>

      </div>

      <div className="category_table">
        <table>
          <thead>
            <tr>
              <th>Category name</th>
              <th>Category id</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {categoryList?.map((elm) => {
              return (
                <tr key={elm.id}>
                  <td>
                    {elm.name}
                  </td>
                  <td>
                    {elm.id}
                  </td>
                  <td>
                    <img width={14} height={14} src="./svg/edit.svg" onClick={() => openModal(elm)} alt="" />
                  </td>
                </tr>
              )
            })}
            <tr></tr>
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal_content">
            <h3>Edit Category</h3>
            <form onSubmit={form1.handleSubmit(submitEdit)}>
              <input
                {...form1.register("name", { required: true })}
                type="text"
                placeholder="Category Name"
              />
              <button type="submit">Save</button>
              <button type="button" onClick={closeModal}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}