import { createSlice } from '@reduxjs/toolkit';
import { addBrandTunk, deleteBrandTunk, editBrandTunk, getBrandTunk } from './brandAPI';

const initialState = {
  brandList: [],
  addedStatus:"",
  addError:"",
  getStatus:"",
  deleteStatus:"",
  editStatus:"",
}

const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    loginModalOpen: (state, action) => {
      state.loginModal = true
      state.registrationModal = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBrandTunk.pending, (state) => {
        state.addedStatus = 'loading';
      })
      .addCase(addBrandTunk.fulfilled, (state, action) => {
        state.addedStatus = 'succeeded';
        state.brandList.push(action.payload)
      })
      .addCase(addBrandTunk.rejected, (state, action) => {
        state.addedStatus = 'failed';
      });
    builder
      .addCase(getBrandTunk.pending, (state) => {
        state.getStatus = 'loading';
      })
      .addCase(getBrandTunk.fulfilled, (state, action) => {
        state.getStatus = 'succeeded';
        state.brandList = action.payload
      })
      .addCase(getBrandTunk.rejected, (state, action) => {
        state.getStatus = 'failed';
      });
    builder
      .addCase(deleteBrandTunk.pending, (state) => {
        state.deleteStatus = 'loading';
      })
      .addCase(deleteBrandTunk.fulfilled, (state, action) => {
        state.deleteStatus = 'succeeded';
      })
      .addCase(deleteBrandTunk.rejected, (state, action) => {
        state.deleteStatus = 'failed';
      });
    builder
      .addCase(editBrandTunk.pending, (state) => {
        state.editStatus = 'loading';
      })
      .addCase(editBrandTunk.fulfilled, (state, action) => {
        state.editStatus = 'succeeded';
      })
      .addCase(editBrandTunk.rejected, (state, action) => {
        state.editStatus = 'failed';
      });
  },
});

export const {
  
} = brandSlice.actions;
export default brandSlice.reducer;
