import React, { useEffect, useState } from 'react';
import "./Login.scss"
import { loginModalClose, loginModalOpen, registrationModalOpen, registrationSucceededModalOpen, registrationSucceededModalClose } from '../../features/login/loginSlice';
import { useForm } from 'react-hook-form'
import { userLoginTunk, userRegistrationTunk } from '../../features/login/loginAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const form1 = useForm()
  const { t, i18n } = useTranslation();

  const {
    loginModal,
    registrationModal,
    loginStatus,
    registrationError,
    loginError,
    registrationSucceeded,
    accessToken
  } = useSelector((state) => state.login);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onSubmit = (data) => {
    dispatch(userRegistrationTunk(data))
  };

  const handleLogin = (data) => {
    dispatch(userLoginTunk(data))
  };

  useEffect(() => {
    if (loginStatus === "succeeded") {
      navigate('/profile')
    }
  }, [loginStatus])

  return (
    <div className='log_reg'>
      {loginModal && (
        <div className='login'>
          <div className='login_close' onClick={() => dispatch(loginModalClose())}>
            <img width={20} height={20} src="/svg/close.svg" alt="" />
          </div>
          <div className='login_title'><p>{t('login.welcome')}</p></div>
          <div className='login_subTitle'><p>{t('login.reg_title')} <span onClick={() => dispatch(registrationModalOpen())}>{t('login.reg')}</span></p></div>
          <form className='login_input' onSubmit={form1.handleSubmit(handleLogin)}>
            <div >
              <input
                {...form1.register("email", { required: true })}
                type="email"
                placeholder={t('login.email')} />
            </div>
            <div >
              <input
                {...form1.register("password", { required: true })}
                type="password"
                placeholder={t('login.password')} />
            </div>
            <div className='login_button'>
              <button type='submit' >{t('login.login')}</button>
            </div>
          </form>
          {loginError && (
            <small style={{ color: "red" }} >{loginError}</small>

          )}
        </div>
      )}

      {registrationModal && (
        <div className='registration'>
          <div className='registration_close' onClick={() => dispatch(loginModalClose())}>
            <img width={20} height={20} src="/svg/close.svg" alt="" />
          </div>
          <div className='registration_title'><p>{t('login.welcome')}</p></div>
          <div className='registration_subTitle'><p>{t('reg.reg_title')}<span onClick={() => dispatch(loginModalOpen())}>{t('login.login')}</span></p></div>
          <form className='registration_input' onSubmit={handleSubmit(onSubmit)}>

            <div className='registration_input_item'>
              <input {...register("lastName", { required: true })} placeholder={t('reg.last')} />
              {errors.lastName && <small>{t('reg.error')}</small>}
            </div>

            <div className='registration_input_item'>
              <input {...register("firstName", { required: true })} placeholder={t('reg.first')} />
              {errors.firstName && <small>{t('reg.error')}</small>}
            </div >

            <div className='registration_input_item'>
              <input type="number" {...register("phone", { required: true, pattern: /^[0-9]+$/ })}  placeholder={t('reg.phone')} />
              {errors.phone && <small>{t('reg.phone_error')}</small>}
            </div>

            <div className='registration_input_item'>
              <input type="email" {...register("email", { required: true })} placeholder={t('reg.email')} />
              {errors.email && <small>{t('reg.email_error')}</small>}
            </div>

            <div className='registration_input_item'>
              <select {...register("gender", { required: true })}>
                <option hidden value="">{t('reg.error')}</option>
                <option value="man">{t('reg.male')}</option>
                <option value="woman">{t('reg.female')}</option>
              </select>
              {errors.gender && <small>{t('reg.error')}</small>}
            </div>

            <div className='registration_input_item'>
              <input type="password" {...register("password", { required: true })} placeholder={t('login.password')} />
              {errors.password && <small>{t('reg.error')}</small>}
            </div>

            {registrationError && (
              <small style={{ color: "red" }}>{registrationError}</small>
            )

            }
            <button type="submit">{t('reg.registration')}</button>
          </form>
        </div>
      )}

      {registrationSucceeded && (
        <div className='succeeded_modal'>
          <div className='succeeded_modal_close' onClick={() => dispatch(registrationSucceededModalClose())}>
            <img width={20} height={20} src="/svg/close.svg" alt="" />
          </div>
          <div className='succeeded_modal_login_title'><p>{t('account.welcome')}</p></div>
          <div className='succeeded_modal_login_subTitle'>
            <p>{t('account.activation')}</p>
          </div>
        </div>
      )}
    </div>
  );
}

