import React from 'react';
import { useForm } from "react-hook-form";
import "./Edit.scss"
import {
  useDispatch,
  useSelector
} from "react-redux"
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export const Edit = ({userInfo}) => {
  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
  const { t, i18n } = useTranslation();

  const onSubmit = async(userData) => {
     await axios.post(`${process.env.REACT_APP_BASE_URL}/user/edite`, {
       userData: userData,
       id: userInfo.id,
     })
  };
  
  return (
    <form className="account-form" onSubmit={handleSubmit(onSubmit)}>
      <section>
        <div className="form-group1">
          <h3>{t('account.contact')}</h3>
          <div>
            <div>
              <input
                {...register("firstName")}
                placeholder={t('reg.first')}
                type="text"
                defaultValue={userInfo?.firstName}
              />
              {errors?.firstName && <small className="error">{t('reg.error')}</small>}
            </div>
            <div>
              <input
                {...register("lastName")}
                placeholder={t('reg.last')}
                type="text"
                defaultValue={userInfo?.lastName}

              />
              {errors.lastName && <small className="error">{t('reg.error')}</small>}
            </div>

          </div>
        </div>
        <div className="form-group2">
          <div>
            <input
              {...register("phone", { pattern: /^\+?[0-9]*$/ })}
              placeholder={t('reg.phone')}
              type="number"
              defaultValue={userInfo?.phone}
            />
            {errors.phone && <small className="error">{t('reg.phone_error')}</small>}
          </div>
          <div>
            <input
              {...register("email", { pattern: /^[^@]+@[^@]+\.[^@]+$/ })}
              placeholder={t('reg.email')}
              type="email"
              defaultValue={userInfo?.email}

            />
            {errors.email && <small className="error">{t('reg.email_error')}</small>}

          </div>

        </div>
        <div className="form-group3 gender">
          <input
            id="male"
            {...register("gender", { required: "Gender is required" })}
            type="radio"
            value="male"
            defaultChecked = {userInfo?.gender === "male" ? true:false}
          />
          <label htmlFor="male">{t('reg.male')}</label>

          <input
            id="female"
            {...register("gender", { required: "Gender is required" })}
            type="radio"
            value="female"
            defaultChecked={userInfo?.gender === "female" ? true:false}
          />
          <label htmlFor="female">{t('reg.female')}</label>
          {errors.gender && <small className="error">{t('reg.error')}</small>}
        </div>
      </section>

      <section>
        <h3>{t('account.myAddress')}</h3>
        <div className="form-group2">
          <input {...register("country")} defaultValue={userInfo?.country} placeholder={t('account.city')} type="text" />
          <input {...register("addres")} defaultValue={userInfo?.addres} placeholder={t('account.adress')}  type="text" />
        </div>
        <div className="form-group2">
          <input {...register("apartment")} defaultValue={userInfo?.apartment} placeholder={t('account.apartment')} type="text" />
          <input {...register("floor")} defaultValue={userInfo?.floor} placeholder={t('account.floor')} type="text" />
          <input {...register("intercom")} defaultValue={userInfo?.intercom} placeholder={t('account.intercom')} type="text" />
        </div>
      </section>
      <div className='account-form_save'>
        <button type="submit" >
        {t('account.save')}
        </button>
      </div>
    </form>
  )
}