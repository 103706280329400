import React ,{useState,useEffect}from 'react';
import "./FavoriteBrands.scss";
import axios from 'axios';
import {
  useNavigate,
  useParams
} from "react-router-dom";

import {
  useDispatch,
  useSelector
} from "react-redux"

export const FavoriteBrands = () => {
  const { userInfo } = useSelector((state) => state.login)
  const [product, setProduct] = useState([])
  const navigate = useNavigate()
  

const getProducts = async (id) => {
  const {
    data
  } = await axios.post(`${process.env.REACT_APP_BASE_URL}/product/get/favorit/product`, {
    UserId: id,
  })
  if (data.length) {
    setProduct(data)
  }
}

useEffect(() => {
  if (userInfo?.id) {
    getProducts(userInfo.id)
  }
}, [ userInfo])

  return (
    <div className='brands'>
      {
        product && product?.map((elm, index) => {
          return (
            <div key={index} className='brands_item'>
              <div className='brands_item_box'>
                <img 
                onClick={() => navigate(`/product/${elm.name}/${elm.id}`)}
                width = {
                  210
                }
                height = {
                  210
                }
                src = {
                    `${process.env.REACT_APP_BASE_IMG_URL}${elm?.ProductImgs[0]?.url}`} alt="" />
                <svg width="14" height="14" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.325 6.75411H11.8389M9.58341 15.8266L14.1276 20.9008C14.4337 21.2426 15.0001 21.0261 15.0001 20.5672V5.125C15.0001 4.02043 14.1047 3.125 13.0001 3.125H6.16675C5.06218 3.125 4.16675 4.02043 4.16675 5.125V20.5672C4.16675 21.0261 4.7331 21.2426 5.03922 20.9008L9.58341 15.8266Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <p>{elm?.name}</p>
              <p>{elm?.price} </p>
            </div>
          )
        })
      }
    </div>
  )
}