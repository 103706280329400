import React, { useEffect, useState } from 'react';
import "./Header.scss"
import BurgerMenu from '../burger-menu/burger';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginModalOpen } from '../../../features/login/loginSlice';
import { getBasketCount } from '../../../features/products/productsSlice';
import { useTranslation } from 'react-i18next';
import { getMeTunk } from '../../../features/login/loginAPI';
import axios from 'axios';

export const Header = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [openSearch, setOpenSearch] = useState(false)
  const [searchResult, setSearchResult] = useState(false)
  const [searchData, setSearchData] = useState()
  const [openSearchData, setOpenSearchData] = useState(false)
  const { t, i18n } = useTranslation();
  const { accessToken } = useSelector((state) => state.login);
  const { basketCount } = useSelector((state) => state.products);
  const [translationActiv, setTranslationActiv] = useState(false)
  const { userInfo } = useSelector((state) => state.login)
  const id = localStorage.getItem("id")

  const handelProfile = () => {
    accessToken ? navigate('/profile') : dispatch(loginModalOpen())
  }

  const handelFavorite = () => {
    accessToken ? navigate('/profile') : dispatch(loginModalOpen())
  }

  const handelBasket = () => {
    accessToken ? navigate('/basket') : dispatch(loginModalOpen())
  }

  const handelHeaderBasket = () => {
    accessToken ? navigate('/basket') : dispatch(loginModalOpen())
  }

  const handelTranslation = () => {
    setTranslationActiv(!translationActiv)
  }

  const handelAdmin = () => {
    navigate('/admin')
  }

  const translationUS = (lng) => {
    i18n.changeLanguage(lng);
    setTranslationActiv(false)
  }

  const translationAM = (lng) => {
    i18n.changeLanguage(lng);
    setTranslationActiv(false)
  }

  const searchInput = () => {
    setOpenSearch(true)
  }

  const search = async () => {
    console.log('searchResult', searchResult);
    await axios.post(`${process.env.REACT_APP_BASE_URL}/product/get/product/by-name`, { name: searchResult }).
      then(res => {
        if (res.data.length > 0) {
          setSearchData(res.data)
          setOpenSearchData(true)
        } else {
          setSearchData([])
          setOpenSearchData(false)
        }
      })
  }

  const seeProduct = (item) => {
    console.log('item', item);
    navigate(`product/${item.category}/${item.id}`)
  }

  const handleOutsideClick = () => {
    setOpenSearchData(false);
  };

  useEffect(() => {
    if (openSearchData) {
      document.addEventListener("click", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [openSearchData]);

  useEffect(() => {
    dispatch(getBasketCount())
    dispatch(getMeTunk({ id }))
  }, [])

  return (
    <div className='header'>
      <div className='header_burger'>
        <Link to={'/'}>IDEAL GIFT.am</Link>
        <BurgerMenu />
      </div>
      <div className='header_container'>
        <div className='header_container_logo'>
          <div className='header_container_logo_img'>
            <Link to={'/'}>IDEAL GIFT.am</Link>
          </div>
          <div className='header_container_logo_nav'>
            <Link to={'/about'}>about</Link>
            <Link to={"/faq"}>faq</Link>
            <Link to={"/contact"}>contact us</Link>
          </div>
        </div>
        <div className='header_container_catalog'>
          <Link to={'/catalog'}>catalog</Link>
        </div>
        <div className='header_container_cart'>
          <div className='header_container_cart_search'>
            {!openSearch && <p onClick={() => searchInput()}>search</p>}
            {openSearch && (
              <div className='header_container_cart_search_box'>
                <input type="text" onChange={(e) => setSearchResult(e.target.value)} placeholder='search' />
                <img onClick={() => search()} width={20} height={20} src="/svg/search.svg" alt="" />
                {openSearchData && (
                  <div className='header_container_cart_search_box_modal'>
                    {
                      searchData?.map((elm) => {
                        return (
                          <div onClick={() => seeProduct(elm)} className='header_container_cart_search_box_modal_item'>
                            <p> {elm?.name} </p>
                          </div>
                        )
                      })
                    }
                  </div>
                )}
              </div>
            )}
          </div>
          <div className='header_container_cart_cart'>
            <a onClick={() => handelHeaderBasket()}>cart({`${basketCount}`})</a>
          </div>
        </div>
      </div>
      <div className='header_tool'>
        <img src="/svg/people.svg" alt="" onClick={() => handelProfile()} />
        <img src="/svg/basket.svg" alt="" onClick={() => handelBasket()} />
        <img src="/svg/heart.svg" alt="" onClick={() => handelFavorite()} />
        <div className='header_tool_translation'>
          <img  src="/svg/world.jpg" alt="" onClick={() => handelTranslation()} />
          <div className={translationActiv ? "header_tool_translation_boxActiv" : "header_tool_translation_box"}>
            <img src="./svg/us.svg" onClick={() => translationUS('en')} alt="" />
            <img src="./svg/am.svg" onClick={() => translationAM('am')} alt="" />
          </div>
        </div>
        {
          userInfo?.role === "admin" && (
            <img width={26} height={30} onClick={() => handelAdmin()} src="./svg/admin.svg" alt="" />
          )
        }
      </div>
    </div>
  );
}
