import React, { useEffect, useState } from "react"
import './Order.scss'
import { PersonalData } from "./personal_data/PersonalData";
import { useDispatch, useSelector } from "react-redux";
import { getMeTunk } from "../../features/login/loginAPI";
import { DeliveryAddress } from "./delivery_address/DeliveryAddress";
import { DetailsAndPayment } from "./details_and_payment/DetailsAndPayment";
import { addErrorMessage, getBasket } from "../../features/products/productsSlice";
import { PriceModal } from "./price_modal/PriceModal";
import axios from 'axios';
import {
  useNavigate
} from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Order = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(1);
  const basket = JSON.parse(localStorage.getItem('basket'))
  const navigate = useNavigate()
  const { t, i18n } = useTranslation();

  const { totalPrice, delivery, deliveryPriceList, errorMessage } = useSelector((state) => state.products);
  const {
    userInfo
  } = useSelector((state) => state.login)
  const id = localStorage.id
  const dispatch = useDispatch()
  const renderLeftContent = () => {
    switch (selectedMenuItem) {
      case 1:
        return <PersonalData />;
      case 2:
        return <DeliveryAddress />;
      case 3:
        return <DetailsAndPayment />;
    }
  };

  const usedNumbers = new Set();

  function generateUniqueNumber(start, end) {
    if (usedNumbers.size >= end - start + 1) {
      throw new Error("Все числа в диапазоне уже были сгенерированы.");
    }

    let randomNumber;
    do {
      randomNumber = Math.floor(Math.random() * (end - start + 1)) + start;
    } while (usedNumbers.has(randomNumber)); 

    usedNumbers.add(randomNumber); 
    return randomNumber;
  }

  const nextStep = async () => {
    const personal_data = localStorage.personal_data && JSON.parse(localStorage.personal_data)
    const delivery_address = localStorage.delivery_address && JSON.parse(localStorage.delivery_address)

    if (personal_data && (personal_data.firstName === '' ||
      personal_data.email === '' ||
      personal_data.lastName === '' ||
      personal_data.phoneNumber === '')
    ) {
      dispatch(addErrorMessage('all fields must be filled'))
      return
    } else if (delivery_address && (delivery_address.address === '' || delivery_address.city == '')) {
      dispatch(addErrorMessage('all fields must be filled'))
      return
    } else {
      dispatch(addErrorMessage(''))

      if (selectedMenuItem === 3) {
        const delMetod = localStorage.getItem('delMetod')
        const peyMet = localStorage.getItem('peyMet')
        const totalPrice = localStorage.getItem('totalPrice')
        const deliveryPrice = localStorage.getItem('deliveryPrice')
        const personal_data = JSON.parse(localStorage.getItem('personal_data'))
        const delivery_address = JSON.parse(localStorage.getItem('delivery_address'))
        const note = localStorage.getItem('note')
        let canCreateOrder = false;
        const orderData = {
          price: Number(totalPrice) - Number(deliveryPrice),
          totalPrice: Number(totalPrice),
          code: 'code',
          fName: personal_data?.firstName,
          lName: personal_data?.lastName,
          phoneNumber: personal_data?.phoneNumber,
          email: personal_data?.email,
          city: delivery_address?.city,
          addres: delivery_address?.address,
          apartment: delivery_address?.apartment,
          floor: delivery_address?.floor,
          intercum: delivery_address?.intercom,
          delMetod: delMetod,
          peyMetod: peyMet,
          orderNot: note ? note : '',
          products: basket.map((item) => {
            return {
              id: item.id,
              count: item.count,
              name: item.name
            }
          }),
          UserId: userInfo.id,
        }
        if (delMetod === 'pickup') {
          if (peyMet === 'cash') {
            navigate(`/payment-status/${3704010}`)
          }
          else {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/product/get/payment/token`, {
              ClientID: `${process.env.REACT_APP_CLIENT_ID}`,
              Username: `${process.env.REACT_APP_USERNAME}`,
              Password: `${process.env.REACT_APP_PASSWORD}`,
              Currency: '051',
              Description: orderData.products.map(product => product.name).join(", "),
              OrderID: generateUniqueNumber(3704014, 3705000),
              Amount: orderData.totalPrice,
              BackURL: process.env.REACT_APP_BACK_URL,
            }).then(res => {
              if (res.data) {
                const redirectUrl = res.data.redirectUrl;
                window.location.href = redirectUrl;
              }
            })
          }
        }
        if (delMetod === 'courier') {
          if (peyMet === 'cash') {
            navigate(`/payment-status/${3704010}`)
          } else {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/product/get/payment/token`, {
              ClientID: `${process.env.REACT_APP_CLIENT_ID}`,
              Username: `${process.env.REACT_APP_USERNAME}`,
              Password: `${process.env.REACT_APP_PASSWORD}`,
              Currency: '051',
              Description: orderData.products.map(product => product.name).join(", "),
              OrderID: 3704009,
              Amount: orderData.totalPrice,
              BackURL: process.env.REACT_APP_BACK_URL,
            }).then(res => {
              if (res.data) {
                const redirectUrl = res.data.redirectUrl;
                window.location.href = redirectUrl;
              }
            })
          }
        }
      } else {
        setSelectedMenuItem(selectedMenuItem + 1)
        localStorage.setItem('step', selectedMenuItem + 1)
      }
    }
  }

  useEffect(() => {
    const step = localStorage.step
    if (step) {
      setSelectedMenuItem(+step)
    } else {
      localStorage.setItem('step', 1)
    }
    dispatch(getMeTunk({ id }))
    dispatch(getBasket())
  }, [])

  return (
    <div className="order">
      <div className="order_container">
        <div className="order_container_left">
          <div className="order_container_left_title">
            <p>{t('order.order')}</p>
          </div>
          <div className="order_container_left_tabs">
            <div>
              <p className={selectedMenuItem === 1 || selectedMenuItem === 2 || selectedMenuItem === 3 ? "activ" : ''}>{t('order.personal_data')}</p>
            </div>
            <div>
              <hr className={selectedMenuItem === 2 || selectedMenuItem === 3 ? "activ" : ''} />
            </div>
            <div>
              <p className={selectedMenuItem === 2 || selectedMenuItem === 3 ? "activ" : ''}>{t('order.delivery_address')}</p>
            </div>
            <div>
              <hr className={selectedMenuItem === 3 ? "activ" : ''} />
            </div>
            <div>
              <p className={selectedMenuItem === 3 ? "activ" : ''}>{t('order.details_payment')}</p>
            </div>
          </div>
          <div className="order_container_left_info">
            <div>
              <img src="/svg/order_req.svg" alt="" />
            </div>
            <div>
              <p>{t('order.fields')}</p>
            </div>
          </div>
          <div className="order_container_left_content">
            {renderLeftContent()}
          </div>
          <div className="order_container_left_button">
            {errorMessage !== '' && <small style={{ color: "red" }}> {errorMessage} </small>}
            <button onClick={() => nextStep()}> {selectedMenuItem === 3 ? 'Pay' : "Next"} </button>
          </div>
        </div>
        <div className="order_container_right">
          <div className="order_container_right_title">
            <p>{t('order.your_order')}</p>
          </div>
          <div className="order_container_right_order">
            {
              basket?.map(elm => {
                return (
                  <div key={elm.id} className="order_container_right_order_item">
                    <div className="order_container_right_order_img">
                      <img width={100} height={100} src={`${process.env.REACT_APP_BASE_IMG_URL}${elm.ProductImgs[0].url}`} />
                    </div>
                    <div className="order_container_right_order_info">
                      <p>{elm?.name}</p>
                      <p>{elm?.price}֏</p>
                      <p>{t('order.quantity')} {elm?.count} </p>
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="order_container_right_delivery">
            <p>{t('order.delivery')}</p>
            <p>{delivery}֏</p>
          </div>
          <div className="order_container_right_total">
            <p>{t('order.total')}</p>
            <p>{totalPrice} ֏</p>
          </div>
        </div>
      </div>
      {deliveryPriceList && (
        <div className="delivery_price_modal">
          <PriceModal />
        </div>
      )}
    </div>

  )
}