import React, { useEffect, useState } from "react"
import "./Catalog.scss"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules'
import "swiper/css";
import useWindowSize from "../components/windowSize/useWindowSize";
import { useNavigate } from "react-router-dom";
import { getCategoryTunk } from "../../features/category/categoryAPI";
import { useDispatch, useSelector } from "react-redux";
import { getBrandTunk } from "../../features/brand/brandAPI";

export const Catalog = () => {
  const [activList, setActivList] = useState(null)
  const [activListName, setActivListName] = useState(null)
  const { width, height } = useWindowSize()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { categoryList } = useSelector((state) => state.category);
  const { brandList } = useSelector((state) => state.brand);

  

  const toLowerCaseText = (text) => {
    return text.toLowerCase();
  }

  useEffect(()=>{
    dispatch(getCategoryTunk())
    dispatch(getBrandTunk())

  },[])

  

  return (
    <div className="catalog">
      <div className='catalog_container'>
        <div className='catalog_container_title'>
          <p>SHOP</p>
        </div>
        <div className='catalog_container_category'>

          <div className="catalog_container_category_list">
            <ul>
              {
                categoryList?.map((elm, index) => {
                  return (
                    <li key={index} onClick={() => navigate(`/product/${elm.name}`)} className={activList === index ? "activ" : ''}>
                      {elm.name}
                    </li>
                  )
                })
              }
            </ul>
            <div className="catalog_container_category_list_item">
              <Swiper
                slidesPerView={
                  width < 1200 && width > 750 ? 3 :
                    width < 750 && width > 600 ? 4 :
                      width < 600 && width > 500 ? 3 :
                        width < 500 ? 2 : 5
                }
                spaceBetween={width < 1200 ? 10 : 30}
                centeredSlides={true}
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                speed={3500}
                modules={[Autoplay]}
                className="mySwiper"
              >
                {brandList.map((elm, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={`${process.env.REACT_APP_BASE_IMG_URL}${elm.images}`}
                      alt={`Image ${index}`}
                      style={{ width: "110px", height: "160px", objectFit: "cover" }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div className="catalog_container_category_slider">
            <div style={{ position: "absolute", left: "-200px", top: "30px" }} className="catalog_container_category_slider_track" >
              <img className="catalog_container_category_slider_track_item" width={130} src={`${process.env.REACT_APP_BASE_IMG_URL}${brandList[0]?.images}`} alt="" />
            </div>
            <div style={{ position: "absolute", left: "20px", top: "100px" }} className="catalog_container_category_slider_track" >
              <img className="catalog_container_category_slider_track_item" width={130} src={`${process.env.REACT_APP_BASE_IMG_URL}${brandList[1]?.images}`} alt="" />
            </div>
            <div style={{ position: "absolute", left: "250px", top: "40px" }} className="catalog_container_category_slider_track" >
              <img className="catalog_container_category_slider_track_item" width={130} src={`${process.env.REACT_APP_BASE_IMG_URL}${brandList[2]?.images}`} alt="" />
            </div>
            <div style={{ position: "absolute", left: "480px", top: "-100px" }} className="catalog_container_category_slider_track" >
              <img className="catalog_container_category_slider_track_item" width={130} src={`${process.env.REACT_APP_BASE_IMG_URL}${brandList[3]?.images}`} alt="" />
            </div>
            <div style={{ position: "absolute", right: "450px", top: "-250px" }} className="catalog_container_category_slider_track" >
              <img className="catalog_container_category_slider_track_item" width={130} src={`${process.env.REACT_APP_BASE_IMG_URL}${brandList[4]?.images}`} alt="" />
            </div>
            <div style={{ position: "absolute", right: "220px", top: "-100px" }} className="catalog_container_category_slider_track" >
              <img className="catalog_container_category_slider_track_item" width={130} src={`${process.env.REACT_APP_BASE_IMG_URL}${brandList[5]?.images}`} alt="" />
            </div>
            <div style={{ position: "absolute", right: "0px", top: "0px" }} className="catalog_container_category_slider_track" >
              <img className="catalog_container_category_slider_track_item" width={130} src={`${process.env.REACT_APP_BASE_IMG_URL}${brandList[6]?.images}`} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}