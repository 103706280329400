import { createSlice } from '@reduxjs/toolkit';
import { addCategoryTunk , editCategoryTunk, getCategoryTunk } from './categoryAPI';

const initialState = {
  categoryList: [],
  addedStatus:"",
  addError:"",
  getStatus:"",
  editStatus:"",
}

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    loginModalOpen: (state, action) => {
      state.loginModal = true
      state.registrationModal = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCategoryTunk.pending, (state) => {
        state.addedStatus = 'loading';
      })
      .addCase(addCategoryTunk.fulfilled, (state, action) => {
        state.addedStatus = 'succeeded';
        state.categoryList.push(action.payload)
      })
      .addCase(addCategoryTunk.rejected, (state, action) => {
        state.addedStatus = 'failed';
      });
    builder
      .addCase(getCategoryTunk.pending, (state) => {
        state.getStatus = 'loading';
      })
      .addCase(getCategoryTunk.fulfilled, (state, action) => {
        state.getStatus = 'succeeded';
        state.categoryList = action.payload
      })
      .addCase(getCategoryTunk.rejected, (state, action) => {
        state.getStatus = 'failed';
      });
    builder
      .addCase(editCategoryTunk.pending, (state) => {
        state.editStatus = 'loading';
      })
      .addCase(editCategoryTunk.fulfilled, (state, action) => {
        state.editStatus = 'succeeded';
      })
      .addCase(editCategoryTunk.rejected, (state, action) => {
        state.editStatus = 'failed';
      });
  },
});

export const {
  
} = categorySlice.actions;
export default categorySlice.reducer;
