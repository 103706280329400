import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { deleteBasketItems, productCountMinus, productCountPlus } from "../../../features/products/productsSlice"

export const BasketTable = ({ item, index }) => {
  const dispatch = useDispatch()
  const { basket } = useSelector((state) => state.products);
console.log('item', item)
  const plusCount = (id) => {
    dispatch(productCountPlus(id))
  }

  const minusCount = (id) => {
    dispatch(productCountMinus(id))
  }

  const deleteProduct = (id) => {
    dispatch(deleteBasketItems(id))
  }

  return (
    <tr>
      <td>{index + 1}</td>
      <td> <img className="basketImg"
      src = {`${process.env.REACT_APP_BASE_IMG_URL}${item?.ProductImgs[0]?.url}`}
      alt = "" /> </td>
      <td style={{ maxWidth: "140px", textAlign: "center" }}>
        <div style={{ display: "flex",  justifyContent: "center", alignItems: "center" }}>
          <p style={{ cursor: "pointer", width: "30px", textAlign: "center" }} onClick={() => minusCount(item.id)}> - </p>
          <p style={{ minWidth: "40px", textAlign: "center", fontSize: "18px", padding: "0" }}>
            {item?.count}
          </p>
          <p style={{ cursor: "pointer", width: "30px", textAlign: "center" }} onClick={() => plusCount(item.id)}> + </p>
        </div>
      </td>
      <td>{item?.price}֏ </td>
      <td>{item?.id}</td>
      <td>
        <img onClick={() => deleteProduct(item?.id)} style={{ cursor: "pointer" }} width={14} height={14} src="/svg/close.svg" alt="" />
      </td>
    </tr>
  )
}