import loginReducer from '../features/login/loginSlice'; 
import brandReducer from '../features/brand/brandSlice'; 
import productsReducer from '../features/products/productsSlice'; 
import categoryReducer from '../features/category/categorySlice'; 
import { configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    products: productsReducer,
    brand: brandReducer,
    category:categoryReducer,
  },
});