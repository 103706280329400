import React from 'react';
import "./About.scss"
import { useTranslation } from 'react-i18next';

export const About = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className='about_us'>
      <div className='about'>
        <div className='about_title' >
          <p>{t('about.about')}</p>
        </div>
        <div className='about_main'>
          <div className='about_main_left'>
            <div>
              <span>{t('about.title_1')}</span>
              <p>{t('about.desc_1_1')}</p>
            </div>
            <div>
              <p>{t('about.desc_1_2')}</p>
            </div>
            <div>
              <span>{t('about.title_2')}</span>
            </div>
            <div>
              <p>{t('about.desc_2')}</p>
            </div>
            <div className='about_info'>
              <p>{t('privacy.privacy')} <a href="/privacy">IdealGift.am</a> </p>
            </div>
          </div>

          <div className='about_main_right'>
            <div>
              <span>{t('about.title_3')}</span>
            </div>
            <div>
              <p>{t('about.desc_3')}</p>
            </div>
            <div>
              <span>{t('about.title_4')}</span>
            </div>
            <div>
              <p>{t('about.desc_4')}</p>
            </div>
            <div>
              <p>{t('about.desc_4_1')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}