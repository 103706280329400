import React from "react";
import "./Corporate.scss"
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import axios from 'axios';
import {
  useNavigate,
  useParams
} from "react-router-dom";

export const Corporate = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { userInfo } = useSelector((state) => state.login)
  const navigate = useNavigate()

  const onSubmit = async(data) => {
    await axios.post(`${process.env.REACT_APP_BASE_URL}/product/add/corporative`, data)
    alert('The order has been accepted, our staff will contact you.')
    navigate('/')
  }

  return (
    <div className="corporate">
      <div className="corporate_section">
        <div className="corporate_section_title">
          <p>CORPORATE SECTION</p>
        </div>
        <div className="corporate_section_container">
          <div className="corporate_section_container_img">
            <img  src="/img/corporate_left.png" alt="" />
          </div>
          <div className="corporate_section_container_main">
            <div className="corporate_section_container_main_title">
              <p>
                We offer the most advantageous
                conditions for corporate gifts
                A large variety of boxes of any design,
                color, size and taste, and about the quality,
                words are superfluous.
              </p>
            </div>
            <div className="corporate_section_container_main_form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <input {...register("firstName")} placeholder="FIRST NAME" defaultValue={userInfo?.firstName} />
                  <input {...register("lastName")} placeholder="LAST NAME" defaultValue={userInfo?.lastName} />
                </div>
                <div>
                  <input {...register("phoneNumber")} placeholder="PHONE NUMBER" defaultValue={userInfo?.phone} />
                </div>
                <div>
                  <input {...register("email")} placeholder="EMAIL" defaultValue={userInfo?.email} />
                </div>
                <div>
                  <textarea {...register("message")}  placeholder="MESSAGE"></textarea>
                </div>
                <div className="corporate_button">
                  <button type="submit">
                    SEND
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="corporate_section_container_corporateImg">
            <img src="/img/corporate.png" alt="" />
          </div>
          <div className="corporate_section_container_img">
            <img src="/img/corporate_right.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}